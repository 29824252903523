import { IFullScreenHero } from 'models/landingpage'
import Link from 'next-translate-routes/link'
import React from 'react'

import useBreakpoints from '../../hooks/use-breakpoints'
import { TrustElement } from './hero-entry'

export interface FullScreenHeroProps {
  data: IFullScreenHero
}

export function getLinkFromCtaTarget(target: any) {
  console.log('target', target)
  if (target.classname?.includes('ategor')) {
    return `/kategorie/${target?.Slug}`
  }
  if (target?.classname?.includes('ecipe')) {
    return `/rezept/${target?.slug}`
  }
  if (target?.classname?.includes('roduc')) {
    return `/produkt/${target?.Slug}`
  }
  return `/${target?.Slug ?? ''}`
}

export const HeroNew = ({ data }: FullScreenHeroProps) => {
  const crop = data.HeroImage?.crop
  const { isXs } = useBreakpoints()
  console.log(data)
  return (
    <div
      className={`relative ${
        data.seventyPercentBackground
          ? `bg-${data.Farbe}-70`
          : `bg-${data.Farbe}`
      }`}
    >
      {data?.TrustText && (
        <TrustElement
          color={data.TrustColor}
          text={data.TrustText}
          position={data.Position}
          key={data.TrustText}
        />
      )}
      <div className={`grid grid-cols-1 sm:grid-cols-2 container mx-auto`}>
        <div
          className={`flex-col justify-center  text-center align-middle hidden sm:flex `}
        >
          <p
            className={`font-bold italic text-headline leading-none ${
              data.Farbe === 'topseller' ? 'text-white' : 'text-black'
            } font-swDisp`}
          >
            {data.Headline}
          </p>
          <p
            className={`font-bold w-full mx-auto sm:w-3/5 leading-none italic text-3xl ${
              data.Farbe === 'topseller' ? 'text-white' : 'text-black'
            } pt-8 font-swDisp`}
          >
            {data.SubHeadline}
          </p>
          <div className={`grid grid-cols-1 sm:grid-cols-2 pt-16`}>
            {data.CtaTarget && (
              <Link
                href={data.CtaTarget && getLinkFromCtaTarget(data.CtaTarget)}
              >
                <a className="inline-block w-4/5 mx-auto p-2 font-bold inline-block border border-black bg-aktion hover:bg-black hover:text-white font-swDisp disabled:bg-gray-200 disabled:cursor-not-allowed">
                  {data.CallToAction}
                </a>
              </Link>
            )}
            {data.CtaTargetScroll && (
              <Link href={`#${data.CtaTargetScroll}`}>
                <a className="inline-block w-4/5 mx-auto p-2 font-bold inline-block border border-black bg-aktion hover:bg-black hover:text-white font-swDisp disabled:bg-gray-200 disabled:cursor-not-allowed">
                  {data.CallToAction}
                </a>
              </Link>
            )}

            {data.CtaTargetSecondary && (
              <Link
                href={
                  data.CtaTargetSecondary &&
                  getLinkFromCtaTarget(data.CtaTargetSecondary)
                }
              >
                <a className="inline-block w-4/5 mx-auto p-2 font-bold inline-block border border-black bg-aktion hover:bg-black hover:text-white font-swDisp disabled:bg-gray-200 disabled:cursor-not-allowed">
                  {data.CallToActionSecondary}
                </a>
              </Link>
            )}
            {data.CtaTargetSecondaryScroll && (
              <Link href={`#${data.CtaTargetSecondaryScroll}`}>
                <a className="inline-block w-4/5 mx-auto p-2 font-bold inline-block border border-black bg-aktion hover:bg-black hover:text-white font-swDisp disabled:bg-gray-200 disabled:cursor-not-allowed">
                  {data.CallToActionSecondary}
                </a>
              </Link>
            )}
          </div>
        </div>
        <div
          className={`relative flex w-full ${
            data.seventyPercentBackground
              ? `bg-70-${data.Farbe}`
              : `bg-${data.Farbe}`
          } ${data.TeaserProduct ? 'mb-8' : ''} h-45 sm:h-75 z-0`}
          style={{
            backgroundImage: `${
              isXs && data.HeroImageMobile
                ? `url(https://spiceworld.azureedge.net/?image=${data.HeroImageMobile?.image.fullpath}`
                : `url(https://spiceworld.azureedge.net/?image=${data.HeroImage?.image.fullpath}&cw=${crop?.cropWidth}&cl=${crop?.cropLeft}&ct=${crop?.cropTop}&ch=${crop?.cropHeight}&bg=1)`
            }`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundColor: 'transparent',
          }}
        ></div>
        <div
          className={`flex-col justify-center align-middle flex sm:hidden text-center ${
            data.seventyPercentBackground
              ? `bg-70-${data.Farbe}`
              : `bg-${data.Farbe}`
          }`}
        >
          <p
            className={`font-bold italic text-4xl leading-none ${
              data.Farbe === 'topseller' ? 'text-white' : 'text-black'
            } font-swDisp pt-4`}
          >
            {data.Headline}
          </p>
          <p
            className={`font-bold  mx-auto w-4/5 leading-tight sm:w-3/5 italic text-lg ${
              data.Farbe === 'topseller' ? 'text-white' : 'text-black'
            } pt-4 font-swDisp`}
          >
            {data.SubHeadline}
          </p>
          <div
            className={`grid grid-cols-1 sm:grid-cols-2 pt-8 space-y-4 pb-8`}
          >
            {data.CtaTarget && (
              <Link
                href={data.CtaTarget && getLinkFromCtaTarget(data.CtaTarget)}
              >
                <a className="inline-block w-4/5 mx-auto p-2 font-bold inline-block border border-black bg-aktion hover:bg-black hover:text-white font-swDisp disabled:bg-gray-200 disabled:cursor-not-allowed">
                  {data.CallToAction}
                </a>
              </Link>
            )}
            {data.CtaTargetScroll && (
              <Link href={`#${data.CtaTargetScroll}`}>
                <a className="inline-block w-4/5 mx-auto p-2 font-bold inline-block border border-black bg-aktion hover:bg-black hover:text-white font-swDisp disabled:bg-gray-200 disabled:cursor-not-allowed">
                  {data.CallToAction}
                </a>
              </Link>
            )}
            {data.CtaTargetSecondary && (
              <Link
                href={
                  data.CtaTargetSecondary &&
                  getLinkFromCtaTarget(data.CtaTargetSecondary)
                }
              >
                <a className="inline-block w-4/5 mx-auto p-2 font-bold inline-block border border-black bg-aktion hover:bg-black hover:text-white font-swDisp disabled:bg-gray-200 disabled:cursor-not-allowed">
                  {data.CallToActionSecondary}
                </a>
              </Link>
            )}
            {data.CtaTargetSecondaryScroll && (
              <Link href={`#${data.CtaTargetSecondaryScroll}`}>
                <a className="inline-block w-4/5 mx-auto p-2 font-bold inline-block border border-black bg-aktion hover:bg-black hover:text-white font-swDisp disabled:bg-gray-200 disabled:cursor-not-allowed">
                  {data.CallToActionSecondary}
                </a>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(HeroNew)
